<template>
    <ValidationObserver ref="validacionItem" class="pt-1">
        <div class="row mx-2">
            <div class="col-8">
                <ValidationProvider v-slot="{errors}" rules="required|min:3|max:20" name="identificador">
                    <el-input v-model="info.prefijo" class="w-100" maxlength="21" :disabled="!foco" @change="volverOriginal" />
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
            </div>
            <div class="col-auto px-2">
                <el-tooltip v-if="foco" class="item" effect="light" content="Guardar" placement="bottom">
                    <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="editarElemento">
                        <i class="icon-ok-circled-outline text-white f-20" />
                    </div>
                </el-tooltip>
                <el-tooltip v-else class="item" effect="light" content="Editar" placement="bottom">
                    <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                        <i class="icon-pencil-outline f-18" @click="foco=true" />
                    </div>
                </el-tooltip>
            </div>
            <div class="col-auto px-0">
                <el-tooltip v-if="canEliminar" class="item" effect="light" content="Eliminar" placement="bottom">
                    <div
                    class="d-middle-center br-10"
                    :class="true?'btn-delete cr-pointer':'text-muted cr-not'"
                    style="width:44px;height:44px;"
                    @click="true?eliminar():''"
                    >
                        <i class="icon-trash-empty f-20" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar identificador" mensaje="¿Desea eliminar el identificador?" @eliminar="deleteEtiqueta" />
    </ValidationObserver>
</template>

<script>
import Proyectos from "~/services/proyectos/proyectos";

export default {
    props:{
        info:{
            type: Object,
            default: () => ({})
        },
        editar:{
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            required: true
        },
        canEliminar:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            identificadorCopy: null,
            foco: false
        }
    },

    mounted(){
        this.identificadorCopy = this.info.prefijo
    },
    methods: {
        async info_tienda(){
            try {
                const { data } = await Proyectos.info_tienda(this.$route.params.id_tienda)
                this.tienda = data.info
                this.coordenadas.lat = this.tienda.latitud * 1
                this.coordenadas.lng = this.tienda.longitud * 1
            } catch (error){
                this.error_catch(error)
            }
        },

        // digito(){
        //     this.foco = true
        // },
        volverOriginal(){
            // console.log('this.identificadorCopy', this.identificadorCopy);
            // this.delay(this.reset())
            // this.info.prefijo = this.identificadorCopy

        },
        // reset(){
        //     this.info.prefijo = this.identificadorCopy
        // },
        async editarElemento(){
            try {
                const valid = await this.$refs.validacionItem.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Formato incorrecto', 'warning')
                    return false
                }
                if(this.editar){
                    const {data} = await Proyectos.editarPrefijo(this.info)
                    this.notificacion('Mensaje', 'Prefijo editado', 'success')
                    this.foco = false
                    return

                }else{
                    this.foco = false
                    // console.log('info.prefijo', this.info.prefijo);
                    // const enviar = {
                    //     ...this.info,
                    //     prefijo: this.info.prefijo
                    // }
                    // // this.lista = {...this.lista, newData}
                    // this.$emit('editar', newData)
                }

            } catch (e){
                this.error_catch(e)
            }

        },
        eliminar(){
            if(this.editar){
                this.deleteEtiqueta()
            }else{
                this.$emit('eliminar', this.index)
            }
        },
        async deleteEtiqueta(){
            try {
                const {data} = await Proyectos.eliminarPrefijo(this.info.id)
                this.notificacion('Mensaje', 'Prefijo eliminado', 'success')
                this.$emit('eliminar', this.index)
            } catch (e){
                this.error_catch(e)
            }

        }

    }
}
</script>

<style lang="css" >
.br-12{
    border-radius: 12px;
}
</style>
